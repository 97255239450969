/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Naples"), React.createElement("p", null, "Pour se loger, Naples s’articule en cinq quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/naples/centro-storico.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Le centre historique"), " : nombreuses églises autour de San Lorenzo, petites rues animées et pizzerias."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/naples/stazione-centrale.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "La gare centrale"), ", à l’est : quartier populaire avec beaucoup d’hôtels, en contact direct avec la vieille ville."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/naples/molo-beverollo.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Molo Beverello"), ", au sud : la gare maritime s’ouvre sur les quartiers espagnols et la Piazza del Plebiscito."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/naples/chiaia.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Chiaia"), ", au sud-ouest : quartier huppé en bord de mer, avec des bars et restaurants branchés."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/naples/vomero.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Vomero"), ", à l’ouest : sur une colline dominant la ville, un quartier tranquille à côté du château Sant’Elmo."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
